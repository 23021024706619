import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { Widget }      from './widget';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class WidgetService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all widgets
   */
  public getWidgets(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Widget[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Widget[]>(environment.api_url + '/widgets', { params: queryParams });
  };
  /**
   * Retrieves widget details by a given id
   *
   * @param idWidget
   */
  public getWidget(idWidget: string): Observable<Widget> { 
    return this.http.get<Widget>(environment.api_url + '/widget/' + idWidget);

  };

  /**
   * Save widget
   *
   * @param widget
   */
  public saveWidget(widget: Widget): Observable<any> { 
    if (widget.idWidget) {
      return this.updateWidget(widget);
    } else {
      return this.newWidget(widget);
    }
  };

  /**
   * Create new widget
   *
   * @param widget
   */
  public newWidget(widget: Widget): Observable<any> { 
    return this.http.post(environment.api_url + '/widget', widget);

  };
  
  /**
   * Update widget
   *
   * @param widget
   */
  public updateWidget(widget: Widget): Observable<any> { 
    return this.http.put(environment.api_url + '/widget', widget);

  };
  
  /**
   * Delete an widget by a given id
   *
   * @param id 
   */
  public removeWidget (idWidget: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/widget/' + idWidget);

  }
  
}
