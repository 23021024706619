import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs/Observable';

import { WidgetGroup }          from './../widgetGroup';
import { WidgetGroupService }   from './../widgetGroup.service';


@Injectable()
export class WidgetGroupListResolver implements Resolve<WidgetGroup[]> {
  constructor(private widgetGroupService: WidgetGroupService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WidgetGroup[]> {
    return this.widgetGroupService.getWidgetGroups(null, 0, 'title', 'asc', '')
  }
}
