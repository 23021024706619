import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs/Observable';

import { Widget }          from './../widget';
import { WidgetService }   from './../widget.service';


@Injectable()
export class WidgetListResolver implements Resolve<Widget[]> {
  constructor(private widgetService: WidgetService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Widget[]> {
    //return this.widgetService.getWidgets()
    return this.widgetService.getWidgets(null, 0, 'name', 'asc', '')
  }
}
