import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { EULA } from './eula';
import { EULASignature } from "../eula/eula-signature";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class EULAService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all eulas
   */
  public getEULAs(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<EULA[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<EULA[]>(environment.api_url + '/eulas', { params: queryParams });
  };
  /**
   * Retrieves eula details by a given id
   *
   * @param idEULA
   */
  public getEULA(idEULA: string): Observable<EULA> { 
    return this.http.get<EULA>(environment.api_url + '/eula/' + idEULA);
  };

  /**
   * Get the currently active eula
   */
  public getCurrentEULA(): Observable<EULA> { 
    return this.http.get<EULA>(environment.api_url + '/eula-current');
  };

  /**
   * Get the next active eula
   */
  public getNextEULA(): Observable<EULA> { 
    return this.http.get<EULA>(environment.api_url + '/eula-next');
  };

  /**
   * Save eula
   *
   * @param eula
   */
  public saveEULA(eula: EULA): Observable<any> {
    if (eula.idEULA) {
      return this.updateEULA(eula);
    } else {
      return this.newEULA(eula);
    }
    //return null;
  };

  /**
   * Create new eula
   *
   * @param eula
   */
  public newEULA(eula: EULA): Observable<any> { 
    return this.http.post(environment.api_url + '/eula', eula);
  };

  /**
   * Update eula
   *
   * @param eula
   */
  public updateEULA(eula: EULA): Observable<any> { 
    return this.http.put(environment.api_url + '/eula', eula);
  };

  /**
   * Delete an eula by a given id
   *
   * @param id
   */
  public removeEULA(idEULA: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/eula/' + idEULA);
  }

  /**
   * Sign an EULA
   *
   * @param signature
   */
  public signEULA(signature: EULASignature): Observable<any> { 
    return this.http.post(environment.api_url + '/eula/sign', signature);
  }

}
