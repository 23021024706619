import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { WidgetGroup }      from './widgetGroup';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class WidgetGroupService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all widgets
   */
  public getWidgetGroups(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<WidgetGroup[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<WidgetGroup[]>(environment.api_url + '/widgetGroups', { params: queryParams });
  };
  /**
   * Retrieves widget details by a given id
   *
   * @param idWidgetGroup
   */
  public getWidgetGroup(idWidgetGroup: string): Observable<WidgetGroup> { 
    return this.http.get<WidgetGroup>(environment.api_url + '/widgetGroup/' + idWidgetGroup);
  };

  /**
   * Save widgetGroup
   *
   * @param widgetGroup
   */
  public saveWidgetGroup(widgetGroup: WidgetGroup): Observable<any> { 
    if (widgetGroup.idWidgetGroup) {
      return this.updateWidgetGroup(widgetGroup);
    } else {
      return this.newWidgetGroup(widgetGroup);
    }
  };

  /**
   * Create new widgetGroup
   *
   * @param widget
   */
  public newWidgetGroup(widgetGroup: WidgetGroup): Observable<any> { 
    return this.http.post(environment.api_url + '/widgetGroup', widgetGroup);
  };
  
  /**
   * Update widgetGroup
   *
   * @param widgetGroup
   */
  public updateWidgetGroup(widgetGroup: WidgetGroup): Observable<any> { 
    return this.http.put(environment.api_url + '/widgetGroup', widgetGroup);
  };
  
  /**
   * Delete an widgetGroup by a given id
   *
   * @param id 
   */
  public removeWidgetGroup(idWidgetGroup: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/widgetGroup/' + idWidgetGroup);
  }
  
}
